@media print {
  @page {
    margin: 0;
  }
  /* body {
    margin: 0px !important;
    padding: 0px !important;
    background-color: #fff !important;
    position: relative; 
    visibility: hidden;
  } */
  .page-show{
    visibility: hidden;
    margin: 0px !important;
    padding: 0px !important;
    display: block;
  }
 
  /* .printable-content, .printable-content * {
    visibility: visible;
  }
  .printable-content {
    position: absolute;
    left: 0;
    top: 0;
  } */
  #lot .print-layout {
    width: 21cm; /* A4 width */
    height: 29.7cm; /* A4 height */
    margin: 0px !important;
    padding: 0px !important;
    padding-inline: 0.25in !important;
    padding-block: 0.35in !important;
    /* margin: 1cm; Margins */
    /* padding: 1cm; */
    page-break-after: always;  
    visibility: visible;
    position: absolute;
    left: 0; 
    top: 0; 
    box-shadow: none !important;
    border-radius: 0px !important;
  }
}

#lot.page-show {
  width: 100%;
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  overflow: auto;
  padding: 1rem;
  background-color: #d9d9d9;
} 

#lot .print-layout {
  ---width-param : 0mm;
  ---width-items : calc( 21cm - var(---width-param) );
  padding: .5rem;
  width: 21cm; /* A4 width */
  height: 29.7cm; /* A4 height */
  box-sizing: border-box;
  box-shadow: 1px 1px 2px 1px #c5c7c5;
  border-radius: 4px;
  background-color: #fff;
  letter-spacing: 0px; 
  color: #000;
}

#lot .print-layout .print-logo {
  position: absolute;
  left: 1.5mm;
  top: 1.5mm;
}

#lot .print-layout .print-head{
  width: 100%;
  position: relative;
  height: 22.6mm;
  padding: 1.4mm;
  border-bottom: 2px solid #000;  
}

#lot .print-layout .print-head-content{
  width: 100%;
  position: relative;
  height: 28mm; 
  padding-block: 1.4mm;
  padding-inline: 2.4mm;
  border-bottom: 2px solid #000; 
  display: inline-flex;
  flex-direction: column;
}

#lot .print-layout .print-foot{
  width: 100%;
  position: relative;
  height: 40mm; 
  padding-block: 1.4mm;
  padding-inline: 2.4mm; 
}

#lot .print-layout .print-content{
  width: 100%;
  position: relative;
  height: 211.34mm; 
  padding-block: 1.4mm;
  padding-inline: 2.4mm;
  border-bottom: 2px solid #000;  
}

.th-text{
  font-size: 13pt;
  font-weight: 700; 
}

.ts-text{
  font-size: 10pt;
  font-weight: 700; 
}

.h-group-text {
  font-weight: 600;
  display: flex; 
  font-size: 10pt;
}

span.h-label {
  width: 180px;
}

.h-group-text span {
  line-height: 1.5rem;
}

.print-layout .h-title {
  display: flex;
  align-items: flex-start;
  font-weight: 600;
}

.print-layout .c-text{
  display: flex;
  align-items: flex-start;
  font-size: 10pt;
  font-weight: 400;
}

#lot .print-layout .sec-content{

}

.print-layout .sec-item {
  width: var(---width-items);
  display: flex;
  flex-direction: column;
}


.print-layout .sec-parm {
  width: var(---width-param);
  display: flex;
  flex-direction: column;
} 